import React from 'react'
import LabelValue from '../../../../components/InlineEdit/LabelValue'
import TextInline from '../../../../components/InlineEdit/TextInline'
import withAlert from '../../../../hoc/withAlert'
import employeeServices from '../../employeeServices'
import EmployeeForm from '../EmployeeForm'

const BasicDetailsComponent = ({employeeDetails,getEmployeeDetails,AppAlert, masters,getMasterLabel}) => {

    return (
        <div className="card">
            { !employeeDetails && 
               <p>...</p> 
            }
            { employeeDetails &&
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="pt-2 ps-2">
                                {getMasterLabel("salutation",employeeDetails.salutation)} {employeeDetails.firstName} {employeeDetails.lastName}
                            </h3>
                            
                        </div>
                        <div className="col-md-6">
                            <span className="float-end p-2">
                                <span className="float-end"><EmployeeForm refreshList={getEmployeeDetails} AppAlert={AppAlert} masters={masters} data={employeeDetails}/></span>
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mx-2">
                            
                            
                            <LabelValue label={"Employee Code"} value={employeeDetails.employeeCode} />
                            <LabelValue label={"Father Name"} value={employeeDetails.fatherName} />
                            <LabelValue label={"Mother Name"} value={employeeDetails.motherName} />
                            <LabelValue label={"Date Of Birth"} value={employeeDetails.dob} />
                            <LabelValue label={"Date Of Joining"} value={employeeDetails.doj} />
                            <LabelValue label={"Email"} value={employeeDetails.email} />
                            <LabelValue label={"Attendance Code"} value={employeeDetails.attendanceCode} />
                            <TextInline label={"Phone"} name="phone" editId={employeeDetails.id} value={employeeDetails.phone} updateInline={employeeServices.updateInline}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12 table-responsive">
                            <table className="table table-bordered">
                                
                                <tbody>
                                    <tr>
                                        <th className="table-primary"  >Branch</th>
                                        <th className="table-secondary">Grade</th>
                                        <th className="table-success"  >Department</th>
                                        <th className="table-danger"   >Designation</th>
                                        <th className="table-warning"  >Division</th>
                                        <th className=""  >Cost Center</th>
                                        <th className="table-info"     >Project</th>
                                        <th className="table-dark"     >Category</th>
                                    </tr>
                                    <tr>
                                        <td className="table-primary"  >{getMasterLabel("branch",employeeDetails.branch)}</td>
                                        <td className="table-secondary">{getMasterLabel("grade",employeeDetails.grade)}</td>
                                        <td className="table-success"  >{getMasterLabel("department",employeeDetails.department)}</td>
                                        <td className="table-danger"   >{getMasterLabel("designation",employeeDetails.designation)}</td>
                                        <td className="table-warning"  >{getMasterLabel("division",employeeDetails.division)}</td>
                                        <td className=""  >{getMasterLabel("cost-center",employeeDetails.costCenter)}</td>
                                        <td className="table-info"     >{getMasterLabel("project",employeeDetails.project)}</td>
                                        <td className="table-dark"     >{getMasterLabel("category",employeeDetails.category)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </div>
        
    )
}

export default withAlert(BasicDetailsComponent);