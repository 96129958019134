
import React from 'react'
import { Link } from 'react-router-dom';
import AppLayout from '../../layout/components/AppLayout';


export default function Dashboard() {

    const dashboardData = false;

  return(
    
      <AppLayout>
        <>


        <div className="container">
            <div className="row mt-3">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <Link to={"/"} >Attendance Report </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        </>
    </AppLayout>

    
 )
}



