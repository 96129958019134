import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';

import { Add, Edit } from '@mui/icons-material';
import employeeServices from '../employeeServices';


export default function EmployeeForm({refreshList, AppAlert, masters, data=null}) {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    

    const initialValues = {
        salutation:"",
        firstName:"",
        lastName:"",
        gender:"",
        fatherName:"",
        motherName:"",
        spouseName:"",
        dob:"",
        doj:"",
        phone:"",
        email:"",
        attendanceCode:"",
        employeeCode:"",
        branch:"",
        category:"",
        costCenter:"",
        department:"",
        designation:"",
        division:"",
        grade:"",
        project:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        employeeServices.save(values).then(res =>{
            refreshList();
            AppAlert(res.msg)
            setDialogStatus(false);
        }).catch(error => {
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        firstName:Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            salutation:data.salutation,
            firstName:data.firstName,
            lastName:data.lastName,
            gender:data.gender,
            fatherName:data.fatherName,
            motherName:data.motherName,
            spouseName:data.spouseName,
            dob:data.dob,
            doj:data.doj,
            phone:data.phone,
            email:data.email,
            attendanceCode:data.attendanceCode,
            employeeCode:data.employeeCode,
            branch:data.branch,
            category:data.category,
            costCenter:data.costCenter,
            department:data.department,
            designation:data.designation,
            division:data.division,
            grade:data.grade,
            project:data.project,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    return (
        <>
           {!data && <button className="btn btn-primary" onClick={addNew}><Add/> Add New</button>}
            {data &&
              <Tooltip title="Update">
                  <IconButton onClick={updateData} style={{padding:"0px"}}>
                      <Edit/>
                  </IconButton>
              </Tooltip>
            } 

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth=""
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Employee"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-9">

                                                <div className="row pt-2">
                                                    {masters && 
                                                        <div className="col-md-2 col-xs-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Salutation" name="salutation"  
                                                                options={masters['salutation']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-4" >
                                                        <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                            label="First Name" name="firstName" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-3" >
                                                        <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                            label="Last Name" name="lastName" 
                                                        />
                                                    </div>

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-3 " >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Gender" name="gender"  
                                                                options={masters['gender']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                </div>

                                                <div className="row pt-2">
                                                    <div className="col-md-4 col-xs-4" >
                                                        <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                            label="Father Name" name="fatherName" 
                                                        />
                                                    </div>
                                                    <div className="col-md-4 col-xs-4" >
                                                        <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                            label="Mother Name" name="motherName" 
                                                        />
                                                    </div>
                                                    <div className="col-md-4 col-xs-4" >
                                                        <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                            label="Spouse Name" name="spouseName" 
                                                        />
                                                    </div>
                                                </div>

                                                <div className="card p-2 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-6 col-xs-6" >
                                                            Date of Birth
                                                        </div>
                                                        <div className="col-md-6 col-xs-6" >
                                                            <FormikControl control="date" formik={formik} type="date" 
                                                                label="" name="dob" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col-md-6 col-xs-6" >
                                                            Date of Joining
                                                        </div>
                                                        <div className="col-md-6 col-xs-6" >
                                                            <FormikControl control="date" formik={formik} type="date" 
                                                                label="" name="doj" 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card p-2 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-12 col-xs-12 text-center" >
                                                            <h5>Contact Information</h5>
                                                        </div>
                                                        <div className="col-md-6 col-xs-6" >
                                                            <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                                label="Phone" name="phone" 
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-xs-6" >
                                                            <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                                label="Email" name="email" 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="row">
                                                    <div className="col-md-12 col-xs-12 text-center" >
                                                            <h5>Official Code</h5>
                                                    </div>
                                                    <div className="col-md-6 col-xs-6" >
                                                        <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                            label="Attendance Code" name="attendanceCode" 
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-xs-6" >
                                                        <FormikControl control="smallTextInput" formik={formik} type="text" 
                                                            label="Employee Code" name="employeeCode" 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">

                                                        {masters && 
                                                            <div className="col-md-12 col-xs-12 pt-2" >
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Branch" name="branch"  
                                                                    options={masters['branch']} multiple={false} 
                                                                />
                                                            </div>
                                                        }
                                                        {masters && 
                                                            <div className="col-md-12 col-xs-12 pt-2" >
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Grade" name="grade"  
                                                                    options={masters['grade']} multiple={false} 
                                                                />
                                                            </div>
                                                        }
                                                        {masters && 
                                                            <div className="col-md-12 col-xs-12 pt-2">
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Department" name="department"  
                                                                    options={masters['department']} multiple={false} 
                                                                />
                                                            </div>
                                                        }

                                                        {masters && 
                                                            <div className="col-md-12 col-xs-12 pt-2">
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Designation" name="designation"  
                                                                    options={masters['designation']} multiple={false} 
                                                                />
                                                            </div>
                                                        }

                                                        {masters && 
                                                            <div className="col-md-12 col-xs-12 pt-2">
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Division" name="division"  
                                                                    options={masters['division']} multiple={false} 
                                                                />
                                                            </div>
                                                        }

                                                        {masters && 
                                                            <div className="col-md-12 col-xs-12 pt-2">
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Cost Center" name="costCenter"  
                                                                    options={masters['cost-center']} multiple={false} 
                                                                />
                                                            </div>
                                                        }

                                                        {masters && 
                                                            <div className="col-md-12 col-xs-12 pt-2">
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Project" name="project"  
                                                                    options={masters['project']} multiple={false} 
                                                                />
                                                            </div>
                                                        }

                                                        {masters && 
                                                            <div className="col-md-12 col-xs-12 pt-2">
                                                                <FormikControl control="autoselect" formik={formik} 
                                                                    label="Category" name="category"  
                                                                    options={masters['category']} multiple={false} 
                                                                />
                                                            </div>
                                                        }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}
