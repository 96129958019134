import React, { useState,useEffect, useCallback } from 'react';
import EmployeePFDetailsForm from './EmployeePFDetailsForm';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import employeeModuleServices from '../../employeeModuleServices';
import ExpandableCard from '../../../../components/ExpandableCard';
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import withAlert from '../../../../hoc/withAlert';
import { Cancel,CheckCircle } from '@mui/icons-material';

const EmployeePFDetailsComponent = ({employeeId, AppAlert, title = "PF Details", limit=1}) => {
    const [PFlist, setPFList] = useState(null)
    const [loading, setLoadingStatus] = useState(false);

    const getPFList = useCallback( () => {
        setLoadingStatus(true);
        employeeModuleServices.get("pf",employeeId).then(res => {
            res.data.length > 0 ? setPFList(res.data) : setPFList(null);
            setLoadingStatus(false);
        }).catch(error=>{
            //AppAlert("Somthing went wrong while loading pf details","error");
            setLoadingStatus(false);
        })
    },[employeeId])

    useEffect(() => {
        getPFList();
    }, [getPFList])

    const remove = (id)=>{
        employeeModuleServices.remove("pf", id).then(res=>{
            getPFList();
            AppAlert("Removed");
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }
    return (
      <>
        <ExpandableCard title={title}>
            {!loading &&
                <div className="card-body">
                    <div className="row">
                        {PFlist &&
                            <>
                                {PFlist.map(pf => {
                                                return (
                                                    <div className="col-md-12 p-2" key={pf.id}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex ">
                                                                    <span>
                                                                        <strong>Applicable : </strong> {pf.pfApplicable? <span className="text-success"><CheckCircle/></span> : <span className="text-danger"> <Cancel/> </span>}<br/>
                                                                        <strong>PF No : </strong> {pf.pfNo}<br/>
                                                                        <strong>UAN : </strong> {pf.uan}<br/>
                                                                        <strong>Applicable Date : </strong>{pf.pfApplicationDate}<br/>
                                                                    </span>
                                                                </div>
                                                                <div style={{position:"absolute",right:10,top:10}}>
                                                                    
                                                                    <EmployeePFDetailsForm employeeId={employeeId} AppAlert={AppAlert} data={pf} refreshList={getPFList} />
                                                                    <ConfirmDialog id={pf.id} confirm={remove} >Are you Sure You want to remove this pf</ConfirmDialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                }
                            </>
                        }
                        {( (PFlist && PFlist.length < limit) || !PFlist) &&
                            <div className="col-md-6 p-4">
                                <div className="">
                                    <div className="text-center">
                                        <p><EmployeePFDetailsForm employeeId={employeeId} AppAlert={AppAlert} refreshList={getPFList}/></p>
                                        <p>{!PFlist ? <span>No pf details available, add new pf details</span> : <span>Add another pf</span>}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {loading && <CommonLoadingSkeleton/>}
        </ExpandableCard>
      </>
    )
}

export default withAlert(EmployeePFDetailsComponent);