import React, { useState,useEffect, useCallback } from 'react';
import ESSDetailsForm from './ESSDetailsForm';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import essServices from './essServices';
import ExpandableCard from '../../../../components/ExpandableCard';
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import withAlert from '../../../../hoc/withAlert';
import { Cancel,CheckCircle } from '@mui/icons-material';

const ESSDetailsComponent = ({employeeId, AppAlert, title = "ESS Details", limit=1}) => {
    const [ESSDetails, setESSDetails] = useState(null)
    const [loading, setLoadingStatus] = useState(false);

    const getESSDetails = useCallback( () => {
        setLoadingStatus(true);
        essServices.get(employeeId).then(res => {
            setESSDetails(res.data);
            setLoadingStatus(false);
        }).catch(error=>{
            //AppAlert("Somthing went wrong while loading ess details","error");
            setLoadingStatus(false);
        })
    },[employeeId])

    useEffect(() => {
        getESSDetails();
    }, [getESSDetails])

    const remove = (id)=>{
        essServices.remove("ess", id).then(res=>{
            getESSDetails();
            AppAlert("Removed");
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }
    return (
      <>
        <ExpandableCard title={title}>
            {!loading &&
                <div className="card-body">
                    <div className="row">
                        {ESSDetails &&
                            <>
                                
                                                
                                                    <div className="col-md-12 p-2" >
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex ">
                                                                    <span>
                                                                        
                                                                        <strong>Access Status : </strong> {ESSDetails.activeStatus? <span className="text-success"><CheckCircle/></span> : <span className="text-danger"> <Cancel/> </span>}<br/>
                                                                        <strong>ESS Username : </strong> {ESSDetails.username}<br/>
                                                                        
                                                                        
                                                                    </span>
                                                                </div>
                                                                <div style={{position:"absolute",right:10,top:10}}>
                                                                    
                                                                    <ESSDetailsForm employeeId={employeeId} AppAlert={AppAlert} data={ESSDetails} refreshList={getESSDetails} />
                                                                    <ConfirmDialog id={ESSDetails.id} confirm={remove} >Are you Sure You want to remove this ess</ConfirmDialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                            
                                
                            </>
                        }
                        {!ESSDetails &&
                            <div className="col-md-6 p-4">
                                <div className="">
                                    <div className="text-center">
                                        <p><ESSDetailsForm employeeId={employeeId} AppAlert={AppAlert} refreshList={getESSDetails}/></p>
                                        <p><span>No ess details available, add ess details</span></p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {loading && <CommonLoadingSkeleton/>}
        </ExpandableCard>
      </>
    )
}

export default withAlert(ESSDetailsComponent);