import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import essServices from './essServices';


const  ESSDetailsForm = ({employeeId, refreshList,AppAlert, data=null}) =>{

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        employeeId: employeeId,
        
        activeStatus:0,
        username:"",
        password:"",

    }

    const validationRule = Yup.object({

        //essApplicable: Yup.string().required("Required"),
        username: Yup.string().required("Required"),


    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            employeeId:employeeId,
            username:data.username,
            activeStatus:data.activeStatus,
            password:"",
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        essServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
      <>
        {!data &&
            <Tooltip title="Add New ESS">
                <IconButton onClick={addNew} style={{padding:"0px"}}>
                    <Add sx={{ fontSize: 80 }} />
                </IconButton>
            </Tooltip>
        }
        {data &&
            <Tooltip title="Update">
                <IconButton onClick={updateData} style={{padding:"0px"}}>
                    <Edit/>
                </IconButton>
            </Tooltip>
        }
        <Dialog
            open={dialogStatus}
            keepMounted
            onClose={handleClose}
            fullWidth={true}
            maxWidth="md"
            aria-describedby="alert-dialog-slide-description"
        >
            <Formik
                initialValues={formValues || initialValues}
                validationSchema={validationRule}
                onSubmit={onSubmit}
                enableReinitialize validateOnMount
            >
                {
                    (formik) => {
                        //console.log(formik.values);
                        return (
                            <Form>
                                <DialogTitle>{"ESS Details"}</DialogTitle>
                                <DialogContent>
                                    <div className="row p-2">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <FormikControl control="switch" formik={formik}
                                                    label="ESS Allow Login" name="activeStatus"
                                                    className="col-md-4 col-xs-12 p-2" 
                                                />
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="UserName" name="username" 
                                                    className="col-md-4 col-xs-12 p-2" 
                                                />
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="password" name="password" 
                                                    className="col-md-4 col-xs-12 p-2" 
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                    {  /* JSON.stringify(formik.values) */ }
                                    </div>
                                    {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                </DialogContent>
                                <DialogActions>
                                    {!savingInProgress && 
                                        <>
                                        <button className="btn btn-success" type="submit">Save</button>
                                        <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                        </>
                                    }
                                </DialogActions>
                            </Form>
                        )
                    }
                }
            </Formik>
        </Dialog>
      </>
    )
}

export default ESSDetailsForm;