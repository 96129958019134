import React, { useEffect, useState } from 'react'
import { Field,ErrorMessage } from 'formik'
import { Autocomplete, Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';




/*  Text Input  */
const hasError = (formik,name) =>{
    //console.log(formik.errors[name]);
    //console.log("touched", formik.touched);
    //console.log("formik",formik.values);
    if(formik.errors[name] !== undefined && formik.touched[name] !== undefined){
        return { error:true, errorMsg : formik.errors[name] };
    } else {
        return { error:false, errorMsg : "" };
    }
    
}


export function Input(props) {
    const {label, name, formik, ...rest} = props;
    return (
        <>
            <Field
                name={name}
            >
                {
                    (props) => {
                        //console.log(props);
                        const {field} = props; //{ field, form, meta }
                        const {error,errorMsg} = hasError(formik,name);
                        return (
                            <>
                            <TextField {...rest} name={name} {...field} label={label} error={ error } helperText={errorMsg}/>
                            </>
                        )
                    }
                }
            </Field>
        </>
    )
}

export function SmallTextInput(props) {
    const {label, name, formik, ...rest} = props;
    return (
        <>
            <Field
                name={name}
            >
                {
                    (props) => {
                        //console.log(props);
                        const {field} = props; //{ field, form, meta }
                        const {error,errorMsg} = hasError(formik,name);
                        return (
                            <>
                            <TextField {...rest} name={name}  size="small"  {...field} label={label} error={ error } helperText={errorMsg} />
                            </>
                        )
                    }
                }
            </Field>
        </>
    )
}

const setDefaultSelectedValues = (values, options, multiple) =>{
    if(multiple){
        if(Array.isArray(values)){
            
            return  values.map(val => {
                return options.findIndex((option)=>{
                    if(val.value){
                        //console.log("val.value",val.value);
                        return option.value === val.value;
                    }
                    else {
                        return option.value === val;
                        //return [];

                    }
                })
            })
        } else {
            return [];
        }
        
    } else {
        return options.findIndex((option)=>{
            return option.value === values;
        })
    }
}


export function AutoSelect(props) {
    const {label, name, options, multiple, formik, classList, onSelectChange, ...rest} = props;
    let [selectedIndex, setSelectedIndex] = useState(null)


    useEffect( ()=>{
        let si = setDefaultSelectedValues(formik.values[name],options,multiple) //Getting the selected Index Here then 
        if(multiple){
            
            //console.log("Hereinside  select index multiple",multiple);
            //console.log("si",si);
            
            if(si.length > 0 ){
                /*let op = si.filter(i =>{
                    if(i !== -1) { console.log("option[i]", options[i]); return options[i] }
                })*/
                let op  = [];
                for(let i of si){
                    if(i !== -1) {op.push(options[i]);}
                }
                //console.log("op",op);
                setSelectedIndex(op);
            } else {
                setSelectedIndex([]);
            }            
        } else {
            let op = options[si];
            //op !== undefined ? setSelectedIndex(op) : setSelectedIndex("");
            //console.log(si);
            if(si>=0){
                setSelectedIndex(op);
            } else {
                setSelectedIndex(null);
            }
            
        }
    },[options,multiple,name,formik.values[name]])

    //console.log("Selected Index-->",selectedIndex);

    if(selectedIndex === undefined){
        return ( <p>Loading..</p> )
    } else {
        if(multiple){
            //console.log(selectedIndex);
        }
        if(multiple === true && selectedIndex === null){
            selectedIndex = [];
        }
        return (
            <>
                <Field
                    name={name}
                >
                    {
                        (props) => {
                            //console.log(props);
                            const { form} = props;
                            const {error,errorMsg} = hasError(formik,name);
                            return (
                                <>
                                <Autocomplete
                                    multiple={multiple}
                                    {...rest}
                                    options={options}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                            formik.setTouched({...form.touched,[name]: true });
                                            if (newValue) {
                                                if(newValue.value){
                                                    formik.setFieldValue(name, newValue.value);
                                                } else {
                                                    formik.setFieldValue(name, newValue);
                                                }
                                                
                                            } else {
                                                formik.setFieldValue(name, "")
                                            }
                                            //console.log("onSelectChange --->",onSelectChange);
                                            if(onSelectChange === undefined){
                                                //console.log("onSelectChange in not Defind Yet",onSelectChange);
                                            } else {
                                                //console.log("onSelectChange in yes Defined",onSelectChange,newValue.value);
                                                onSelectChange(newValue.value)
                                            }
                                        }}
                                    filterSelectedOptions
                                    value={selectedIndex}
                                    renderInput={(params) => {
                                            //console.log(error);
                                            return (
                                                    <TextField
                                                        {...params}
                                                        error={ error }
                                                        helperText={errorMsg}
                                                        {...rest}
                                                        autoComplete="off"
                                                        className={classList}
                                                        label={label}
                                                        onBlur={()=>{ formik.setTouched({...form.touched,[name]: true });  }}
                                                        size="small"
                                                        placeholder={"Select "+label}
                                                    />
                                                )
                                            }
                                        }
                                    />
                                </>
                            )
                        }
                    }
                </Field>
                
            </>
        )
    }
}

export function CheckboxInput(props) {
    const {label, name, formik} = props;
    let val = formik.values[name] === 0 ? false : true;
    return (
        <>
            <FormControlLabel 
                control={
                    
                    <Checkbox
                            name={name}
                            checked={val}
                            onChange={(event, checked) => {
                                //console.log(event.target.checked);
                                formik.setFieldValue(name, event.target.checked);
                            }}
                    />
                    }
                label={label}
            />
        </>
    )
}

/*  Radio Group  */
export function Radio(props){
    const {label, name, options, onSelectChange, formik, ...rest} = props;
    return (
        <>
            <div className="manage_user_radio">
                <p>{label}</p>

                <Field name={name} {...rest} >
                    {
                        
                        ({ field }) => {
                            return options.map(option => {
                                return (
                                    <div className="user_radio" key={option.value}>
                                    <React.Fragment key={option.label}>
                                        <input
                                            className="mange_user_radio_btn"
                                            type='radio'
                                            {...field}
                                            value={option.value}
                                            checked={field.value.includes(option.value.toString())}
                                        />
                                        <span>{option.label}</span>
                                    </React.Fragment>
                                    </div>
                                );
                            })
                        }
                    }
                </Field>

                <ErrorMessage name={name}>{errorMsg => <span className="formError">{errorMsg} </span>}</ErrorMessage>
            </div>
        </>
    )
}

export function DatePicker(props) {
    const {label, name, formik, ...rest} = props;
    return (
        <>
            <Field
                name={name}
            >
                {
                    (props) => {
                        //console.log(props);
                        const {field} = props; //{ field, form, meta }
                        const {error,errorMsg} = hasError(formik,name);
                        return (
                            <>
                            <TextField {...rest} name={name} {...field} label={label} variant="" error={ error } size="small" variant="standard" helperText={errorMsg}/>
                            </>
                        )
                    }
                }
            </Field>
            
        </>
    )
}



export function FormikSwitch(props) {
    const {label, name, formik,rest} = props;
    let val = formik.values[name] == 0 ? false : true;
    return (
        <>
            <FormControlLabel 
                control={
                    <Switch  
                            {...rest}
                            name={name}
                            checked={val}
                            onChange={(event, checked) => {
                                //console.log(event.target.checked);
                                formik.setFieldValue(name, checked);
                            }}
                    />
                    }
                label={label}
            />
        </>
    )
}


