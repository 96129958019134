import React, { useState,useEffect, useCallback } from 'react';
import EmployeeBankDetailsForm from './EmployeeBankDetailsForm';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import employeeModuleServices from '../../employeeModuleServices';
import ExpandableCard from '../../../../components/ExpandableCard';
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import withAlert from '../../../../hoc/withAlert';

const EmployeeBankDetailsComponent = ({employeeId, AppAlert, title = "Bank Details", limit=1}) => {
    const [bankList, setBankList] = useState(null)
    const [loading, setLoadingStatus] = useState(false);

    const getBankList = useCallback( () => {
        setLoadingStatus(true);
        employeeModuleServices.get("bank",employeeId).then(res => {
            res.data.length > 0 ? setBankList(res.data) : setBankList(null);
            setLoadingStatus(false);
        }).catch(error=>{
            //AppAlert("Somthing went wrong while loading bank details","error");
            setLoadingStatus(false);
        })
    },[employeeId])

    useEffect(() => {
        getBankList();
    }, [getBankList])

    const remove = (id)=>{
        employeeModuleServices.remove("bank", id).then(res=>{
            getBankList();
            AppAlert("Removed");
        }).catch(error=>{

            AppAlert(error.msg,"error")
        })
    }
    return (
      <>
        <ExpandableCard title={title}>
            {!loading &&
                <div className="card-body">
                    <div className="row">
                        {bankList &&
                            <>
                                {bankList.map(bank => {
                                                return (
                                                    <div className="col-md-12 p-2" key={bank.id}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex ">
                                                                    <span>
                                                                        {bank.bankName}<br/>
                                                                        {bank.accountName},{bank.accountNo}<br/>
                                                                        {bank.ifsc}, {bank.branch}<br/>
                                                                    </span>
                                                                </div>
                                                                <div style={{position:"absolute",right:10,top:10}}>
                                                                    
                                                                    <EmployeeBankDetailsForm employeeId={employeeId} AppAlert={AppAlert} data={bank} refreshList={getBankList} />
                                                                    <ConfirmDialog id={bank.id} confirm={remove} >Are you Sure You want to remove this bank</ConfirmDialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                }
                            </>
                        }
                        {( (bankList && bankList.length < limit) || !bankList) &&
                            <div className="col-md-6 p-4">
                                <div className="">
                                    <div className="text-center">
                                        <p><EmployeeBankDetailsForm employeeId={employeeId} AppAlert={AppAlert} refreshList={getBankList}/></p>
                                        <p>{!bankList ? <span>No bank details available, add new bank details</span> : <span>Add another bank</span>}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {loading && <CommonLoadingSkeleton/>}
        </ExpandableCard>
      </>
    )
}

export default withAlert(EmployeeBankDetailsComponent);