import api from "../../../../api/api";

const get = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/ess/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/ess",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const remove = (id) => {
    return new Promise((resolve, reject)=>{
        api.delete("ess/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const essServices = {
    get,
    save,
    remove,
}
export default essServices;