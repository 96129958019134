import api from "../../api/api";


const get = (module,employeeId) => {
    return new Promise((resolve, reject)=>{
        api.get('employee-module/'+employeeId+"/"+module).then((res) => {
            resolve(res.data);
        }).catch(error =>{
            reject({
                message: "Something went wrong while loading module",
                error : error
            })
        })
    })
}

const save = (module,payload) =>{
    return new Promise((resolve, reject)=>{

        api.post("employee-module/"+module,payload).then( (res)=>{
            console.log(res.data)
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
        
    })
}


const remove = (module,id) => {
    return new Promise((resolve, reject)=>{
        api.delete("employee-module/"+module+"/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const employeeModuleServices = {
    get,
    save,
    remove,
}

export default employeeModuleServices;