import React, { useState,useEffect, useCallback } from 'react';
import EmployeeESICDetailsForm from './EmployeeESICDetailsForm';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import employeeModuleServices from '../../employeeModuleServices';
import ExpandableCard from '../../../../components/ExpandableCard';
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import withAlert from '../../../../hoc/withAlert';
import { Cancel,CheckCircle } from '@mui/icons-material';

const EmployeeESICDetailsComponent = ({employeeId, AppAlert, title = "ESIC Details", limit=1}) => {
    const [ESIClist, setESICList] = useState(null)
    const [loading, setLoadingStatus] = useState(false);

    const getESICList = useCallback( () => {
        setLoadingStatus(true);
        employeeModuleServices.get("esic",employeeId).then(res => {
            res.data.length > 0 ? setESICList(res.data) : setESICList(null);
            setLoadingStatus(false);
        }).catch(error=>{
            //AppAlert("Somthing went wrong while loading esic details","error");
            setLoadingStatus(false);
        })
    },[employeeId])

    useEffect(() => {
        getESICList();
    }, [getESICList])

    const remove = (id)=>{
        employeeModuleServices.remove("esic", id).then(res=>{
            getESICList();
            AppAlert("Removed");
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }
    return (
      <>
        <ExpandableCard title={title}>
            {!loading &&
                <div className="card-body">
                    <div className="row">
                        {ESIClist &&
                            <>
                                {ESIClist.map(esic => {
                                                return (
                                                    <div className="col-md-12 p-2" key={esic.id}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex ">
                                                                    <span>
                                                                        <strong>Applicable : </strong> {esic.esicApplicable? <span className="text-success"><CheckCircle/></span> : <span className="text-danger"> <Cancel/> </span>}<br/>
                                                                        <strong>ESIC No : </strong> {esic.esicNo}<br/>
                                                                        <strong>UAN : </strong> {esic.uan}<br/>
                                                                        <strong>Applicable Date : </strong>{esic.esicApplicationDate}<br/>
                                                                    </span>
                                                                </div>
                                                                <div style={{position:"absolute",right:10,top:10}}>
                                                                    
                                                                    <EmployeeESICDetailsForm employeeId={employeeId} AppAlert={AppAlert} data={esic} refreshList={getESICList} />
                                                                    <ConfirmDialog id={esic.id} confirm={remove} >Are you Sure You want to remove this esic</ConfirmDialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                }
                            </>
                        }
                        {( (ESIClist && ESIClist.length < limit) || !ESIClist) &&
                            <div className="col-md-6 p-4">
                                <div className="">
                                    <div className="text-center">
                                        <p><EmployeeESICDetailsForm employeeId={employeeId} AppAlert={AppAlert} refreshList={getESICList}/></p>
                                        <p>{!ESIClist ? <span>No esic details available, add esic details</span> : <span>Add another esic</span>}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {loading && <CommonLoadingSkeleton/>}
        </ExpandableCard>
      </>
    )
}

export default withAlert(EmployeeESICDetailsComponent);