import React, { useEffect, useRef, useState,useCallback } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from "../../../layout/components/AppLayout"
import employeeMasterServices from '../../../master/employeeMasterServices'
import EmployeeForm from '../components/EmployeeForm'
import employeeServices from '../employeeServices'
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom'

import { FileDownload } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';


import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton'
import useEmployeeMasterList from '../../../hooks/useEmployeeMasterList'



const CellLink = (props) =>{
    return (
        <>  
            <Link className="" to={`/employee/details/${props.data.id}`}> {props.data.firstName} {props.data.lastName} </Link>
        </>
    )
}

const EmployeeListPage = ({AppAlert}) => {

    const [masters,getMasterLabel] = useEmployeeMasterList(["branch","category","cost-center", "department",  "designation", "division","document-type","gender" ,'grade','project',"salutation"]); 
    const [employeeList,setEmployeeList] = useState([]);
    const gridRef = useRef();


    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    })
    const [columnDefs,setColumnDefs] = useState([]);

    const setupColDef = () =>{
        setColumnDefs([
            {headerName: "Sr No",valueGetter: "node.rowIndex + 1"},
            {field:"firstName",sortable: true, colId:"employee.firstName",  filter:true, cellRenderer: CellLink, headerName: 'Employee Name'},
            {field:"Gender", valueGetter: params => getMasterLabel("gender",params.data.gender)},
            {field:"dob", valueGetter: params => moment(params.data.dob).format("DD-MMM-YYYY")},
            {field:"doj", valueGetter: params => moment(params.data.doj).format("DD-MMM-YYYY")},
            {field:"branch", valueGetter: params => getMasterLabel("branch",params.data.branch)},
            {field:"grade", valueGetter: params => getMasterLabel("grade",params.data.grade)},
            {field:"department", valueGetter: params => getMasterLabel("department",params.data.department)},
            {field:"designation", valueGetter: params => getMasterLabel("designation",params.data.designation)},
            {field:"division", valueGetter: params => getMasterLabel("division",params.data.division)},
            {field:"costCenter", valueGetter: params => getMasterLabel("cost-center",params.data.costCenter)},
        ]);
    }


    const onGridReady =(params)=>{
        getEmployeeList();
    }


    const [loading,setLoadingStatus] = useState(true);

    const getEmployeeList = () =>{
        setLoadingStatus(true);
        employeeServices.getList().then(res=>{
            setEmployeeList(res.data);
            setLoadingStatus(false);
            setupColDef()
        })
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);


    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {masters && 
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <button type="button" onClick={onBtnExport} className="btn btn-primary float-end"> <FileDownload/> Export</button>
                            <span className="float-end"><EmployeeForm refreshList={getEmployeeList} AppAlert={AppAlert} masters={masters} /></span>
                        </div>
                        <div className="col-md-12">
                        {loading && <LinearProgress  /> }
                        </div>
                        <div className="col-md-12">
                        <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={employeeList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    onGridReady={onGridReady}
                                    >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )

}





/*
const EmployeeListPage = ({AppAlert}) => {


    const [masters,getMasterLabel] = useEmployeeMasterList(["branch","category","cost-center", "department",  "designation", "division","document-type","gender" ,'grade','project',"salutation"]); 
    const [rowData,setRowData] = useState(null);
    const [loading, setLoadingStatus] = useState(false);
    const [columnDefs,setColumnDefs] = useState(null);



    const commonColDefs = [
        { field:"id",sortable: true, filter:true, width:80 },
        { field:"firstName",sortable: true, colId:"employee.firstName",  filter:true, cellRenderer: CellLink, headerName: 'Employee Name'},
        {field:"Gender", valueGetter: params => getMasterLabel("gender",params.data.gender)},
        {field:"Branch", valueGetter: params => getMasterLabel("branch",params.data.branch)},
        
    ]

    const [defaultColDef] = useState({
        floatingFilter: true,
    })


    const getData = () =>{
        setLoadingStatus(true);
        employeeServices.getList().then(res=>{
            setRowData(res.data);
            setColumnDefs(commonColDefs);
            setLoadingStatus(false);
        })
    }


    useEffect(()=>{
        //getMasterList();
        getData();
    },[])
    return (
        <AppLayout>
            <div className="container">
                <div className="row mt-2">
                    <div className="co-md-12">
                        <span className="float-end"><EmployeeForm refreshList={getData} AppAlert={AppAlert} masters={masters} /></span>
                    </div>
                </div>

                <div className="row">
                          <div className="col-md-12">
                            {loading &&  <CommonLoadingSkeleton/> }
                            {! loading && rowData==null && <h3>No data Available</h3> }
                            <div className="ag-theme-alpine" style={{height: 400}}>
                              <AgGridReact
                                  rowData={rowData}
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  onCellValueChanged = {(params)=>{ updateData(params) }}
                                  >
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
            </div>
        </AppLayout>
    )
}

*/


export default withAlert(EmployeeListPage);