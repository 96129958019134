import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from "../../../layout/components/AppLayout"

import TimeShiftForm from '../components/TimeShiftForm'
import timeShiftServices from '../timeShiftServices'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom'


import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton'



const CellLink = (props) =>{
    return (
        <>  
            <Link className="" to={`/timeshift/details/${props.data.id}`}> {props.data.title} </Link>
        </>
    )
}



const TimeShiftListPage = ({AppAlert}) => {


    const [masters,setMasters] = useState(null); 
    const [rowData,setRowData] = useState(null);
    const [loading, setLoadingStatus] = useState(false);
    const [columnDefs,setColumnDefs] = useState(null);


    const commonColDefs = [
        { field:"id",sortable: true, filter:true, width:80 },
        { field:"firstName",sortable: true, colId:"attendance_time_shift.title",  filter:true, cellRenderer: CellLink, headerName: 'TimeShift Name'},
        
    ]

    const [defaultColDef] = useState({
        floatingFilter: true,
    })

    


    const getData = () =>{
        setLoadingStatus(true);
        timeShiftServices.getList().then(res=>{
            setRowData(res.data);
            setColumnDefs(commonColDefs);
            setLoadingStatus(false);
        })
    }


    const updateData =(params) =>{
        console.log(params);
    }


    useEffect(()=>{
        getData();
    },[])
    return (
        <AppLayout>
            <div className="container">
                <div className="row mt-2">
                    <div className="co-md-12">
                        <span className="float-end"><TimeShiftForm refreshList={getData} AppAlert={AppAlert} masters={masters} /></span>
                    </div>
                </div>

                <div className="row">
                          <div className="col-md-12">
                            {loading &&  <CommonLoadingSkeleton/> }
                            {! loading && rowData==null && <h3>No data Available</h3> }
                            <div className="ag-theme-alpine" style={{height: 400}}>
                              <AgGridReact
                                  rowData={rowData}
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  onCellValueChanged = {(params)=>{ updateData(params) }}
                                  >
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
            </div>
        </AppLayout>
    )
}


export default withAlert(TimeShiftListPage);