import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import TimeShiftForm from '../components/TimeShiftForm'
import timeShiftServices from '../timeShiftServices'


const  TimeshiftDetailsPage = ({AppAlert}) => {

    const {id} = useParams();

    const [timeShiftData,setTimeShiftData] = useState(null)
    const getTimeShiftData = useCallback(()=>{
        timeShiftServices.get(id).then(res=>{
            setTimeShiftData(res.data);
        }).catch(error=>{
            //AppAlert(error.msg,"error")
        })
    },[id]);

    useEffect(()=>{
        getTimeShiftData()
    },[getTimeShiftData])



    return (
        <AppLayout>
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow">
                            <div className="card-header">
                                <span className="float-start"> Timeshift Details</span>                                
                                <span className="float-end"><TimeShiftForm refreshList={getTimeShiftData} AppAlert={AppAlert} data={timeShiftData}/></span>
                            </div>
                            <div className="card-body">
                                { !timeShiftData && 
                                <p>...</p> 
                                }
                                { timeShiftData &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="float-end">
                                                    
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}


export default withAlert(TimeshiftDetailsPage)