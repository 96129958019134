import {useState,useEffect} from 'react'
import employeeMasterServices from '../master/employeeMasterServices';


function useEmployeeMasterList(masterNameList,type) {

    /* Start Masters Setting  */
    const [masters,setMasters] = useState(null);
    const getMasterList = () => {
        if(type === undefined) {
            employeeMasterServices.get(masterNameList).then(res=>{
                setMasters(res);
            })
        } else {
            employeeMasterServices.getWithAllOptions(masterNameList).then(res=>{
                setMasters(res);
            })
        }
    }
    const getMasterLabel = (type, id) => {
        try{
            let label = masters[type].find(m => m.value === id)
            return label.label;
        } catch(e){
            //console.log(type,id);
            return "";
        }
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* End Masters Setting  */

    return [masters,getMasterLabel];
}

export default useEmployeeMasterList