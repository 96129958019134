import React, { useState,useEffect, useCallback } from 'react';
import EmployeeAddressForm from './EmployeeAddressForm';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { ContactMail, ContactPhone } from '@mui/icons-material';
import employeeModuleServices from '../../employeeModuleServices';
import ExpandableCard from '../../../../components/ExpandableCard';
import CommonLoadingSkeleton from '../../../../components/CommonLoadingSkeleton';
import withAlert from '../../../../hoc/withAlert';

const EmployeeAddressComponent = ({employeeId, AppAlert, title = "Address Details", limit=3}) => {
    const [addressList, setAddressList] = useState(null)
    const [loading, setLoadingStatus] = useState(false);

    const getAddressList = useCallback( () => {
        setLoadingStatus(true);
        employeeModuleServices.get("address",employeeId).then(res => {
            res.data.length > 0 ? setAddressList(res.data) : setAddressList(null);
            setLoadingStatus(false);
        }).catch(error=>{
            //AppAlert("Somthing went wrong while loading address","error");
            setLoadingStatus(false);
        })
    },[employeeId])

    useEffect(() => {
        getAddressList();
    }, [getAddressList])

    const remove = (id)=>{
        employeeModuleServices.remove("address", id).then(res=>{
            getAddressList();
            AppAlert("Removed");
        }).catch(error=>{

            AppAlert(error.msg,"error")
        })
    }
    return (
      <>
        <ExpandableCard title={title}>
            {!loading &&
                <div className="card-body">
                    <div className="row">
                        {addressList &&
                            <>
                                {addressList.map(address => {
                                                return (
                                                    <div className="col-md-6 p-2" key={address.id}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex ">
                                                                    <span>
                                                                        {address.contactPerson}<br/>
                                                                        {address.addressLine1},{address.addressLine2}<br/>
                                                                        {address.city}, {address.state}, {address.zip}<br/>
                                                                        {address.contactPhone && address.contactPhone != null ? 
                                                                            <><ContactPhone/> {address.contactPhone}</> : "..."}<br/>
                                                                        {address.contactEmail && address.contactEmail != null ? 
                                                                            <><ContactMail/> {address.contactEmail}</> : "..."} 
                                                                        
                                                                    </span>
                                                                </div>
                                                                <div style={{position:"absolute",right:10,top:10}}>
                                                                    
                                                                    <EmployeeAddressForm employeeId={employeeId} AppAlert={AppAlert} data={address} refreshList={getAddressList} />
                                                                    <ConfirmDialog id={address.id} confirm={remove} >Are you Sure You want to remove this address</ConfirmDialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                }
                            </>
                        }
                        {( (addressList && addressList.length < limit) || !addressList) &&
                            <div className="col-md-6 p-4">
                                <div className="">
                                    <div className="text-center">
                                        <p><EmployeeAddressForm employeeId={employeeId} AppAlert={AppAlert} refreshList={getAddressList}/></p>
                                        <p>{!addressList ? <span>No address available add new address</span> : <span>Add another address</span>}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {loading && <CommonLoadingSkeleton/>}
        </ExpandableCard>
      </>
    )
}

export default withAlert(EmployeeAddressComponent);