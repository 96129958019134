import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import BasicDetailsComponent from '../components/BasicDetails/BasicDetailsComponent'
import EmployeeAddressComponent from '../components/EmployeeAddress/EmployeeAddressComponent'
import EmployeeBankDetailsComponent from '../components/EmployeeBankDetails/EmployeeBankDetailsComponent'
import EmployeeESICDetailsComponent from '../components/EmployeeESICDetails/EmployeeESICDetailsComponent'
import EmployeePFDetailsComponent from '../components/EmployeePFDetails/EmployeePFDetailsComponent'
import ESSDetailsComponent from '../components/ESSDetails/ESSDetailsComponent'
import useEmployeeMasterList from '../../../hooks/useEmployeeMasterList'
import employeeServices from '../employeeServices'
import ImageInline from '../../../components/InlineEdit/ImageInline'
import ProfileImageInlineAws from '../../../components/InlineEdit/ProfileImageInlineAws'




const  EmployeeDetailsPage = ({AppAlert}) => {

    const {id} = useParams();
    const [masters,getMasterLabel] =  useEmployeeMasterList(["branch","category","cost-center", "department",  "designation", "division","document-type","gender" ,'grade','project',"salutation"]);

    const [employeeDetails,setEmployeeDetails] = useState(null)

    const getEmployeeDetails = ()=>{
        employeeServices.get(id).then(res=>{
            setEmployeeDetails(res.data);
        }).catch(error=>{
            //AppAlert(error.msg,"error")
        })
    };

    useEffect(()=>{
        getEmployeeDetails()
    },[id])


    return (
        <AppLayout>
            <div className="container-fluid">
                
                <div className="row mt-3">
                    <div className="col-md-3">
                        {employeeDetails &&
                            <>
                                <ProfileImageInlineAws 
                                        label={"Profile Image"}
                                        editId={employeeDetails.id} 
                                        name="profileImage"
                                        value={employeeDetails.profileImage}
                                        updateInline={employeeServices.updateInline}
                                        fileId={employeeDetails.employeeCode}
                                        module="employee"
                                />

                                <div class="list-group">
                                    <a href="#employeeAddress" class="list-group-item list-group-item-action">Address</a>
                                    <a href="#employeeBankDetails" class="list-group-item list-group-item-action">Bank Details</a>
                                    <a href="#employeePFDetails" class="list-group-item list-group-item-action">PF Details</a>
                                    <a href="#employeeESICDetails" class="list-group-item list-group-item-action">ESIC Details</a>
                                    <a href="#employeeESSDetails" class="list-group-item list-group-item-action">ESS Details</a>

                                </div>

                            </>
                        }
                    </div>
                    <div className="col-md-9">
                        
                        {masters && 
                            <BasicDetailsComponent 
                                employeeDetails={employeeDetails}
                                getEmployeeDetails={getEmployeeDetails}
                                masters={masters} 
                                getMasterLabel={getMasterLabel} 
                            />
                        }
                        
                        
                        <div id="employeeAddress">
                            <EmployeeAddressComponent employeeId={id} title="Address" />
                        </div>
                        <div id="employeeBankDetails">
                            <EmployeeBankDetailsComponent employeeId={id} title="Bank Details" />
                        </div>
                        <div id="employeePFDetails">
                            <EmployeePFDetailsComponent employeeId={id} title="PF Details" />
                        </div>
                        <div id="employeeESICDetails">
                            <EmployeeESICDetailsComponent employeeId={id} title="Esic Details" />
                        </div>
                        <div id="employeeESSDetails">
                            <ESSDetailsComponent employeeId={id} title="Employee Self Service Details" />
                        </div>
                    </div>
                </div>
                
                
            </div>
        </AppLayout>
    )
}


export default withAlert(EmployeeDetailsPage)