import React from 'react'
import { Route, Routes } from 'react-router'
import Login from '../auth/pages/Login'
import Logout from '../auth/pages/Logout'
import Register from '../auth/pages/Register'
import Profile from '../auth/pages/Profile'
import Dashboard from '../dashboard/pages/Dashboard'
import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'
import NotificationComponent from '../modules/Notification/NotificationComponent'
import BusinessListPage from '../modules/Business/BusinessListPage'
import SystemMasterListPage from '../master/pages/SystemMasterListPage'
import EmployeeMasterListPage from '../master/pages/EmployeeMasterListPage'
import EmployeeListPage from '../app/employee/pages/EmployeeListPage'
import EmployeeDetailsPage from '../app/employee/pages/EmployeeDetailsPage'
import TimeShiftListPage from '../app/timeshift/pages/TimeShiftListPage'
import TimeShiftDetailsPage from '../app/timeshift/pages/TimeShiftDetailsPage'
import SelectPortalPage from '../auth/pages/SelectPortalPage'





export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={ <Dashboard/> } />
      <Route path="/auth/select-portal" element={ <SelectPortalPage /> } />
      <Route path="/auth/login" element={ <Login/> } />
      <Route path="/auth/register" element={ <Register/> } />
      <Route path="/auth/profile" element={ <Profile/> } />
      <Route path="/auth/logout" element={ <Logout/> } />
      <Route path="/notification/list" element={ <NotificationComponent /> } />
      <Route path="/system-master/list" element={ <SystemMasterListPage /> } />
      <Route path="/employee-master/list" element={ <EmployeeMasterListPage /> } />
      <Route path="/business/details" element={ <BusinessListPage /> } />
      <Route path="/user/list" element={ <UserListPage/> } />
      <Route path="/user/role" element={ <UserRolePage/> } />



      {/* App Routs  */}
      <Route path="/employee/list" element={<EmployeeListPage/> } />
      <Route path="/employee/details/:id" element={<EmployeeDetailsPage/> } />

      {/* Time Shift Routs  */}
      <Route path="/timeshift/list" element={<TimeShiftListPage/> } />
      <Route path="/timeshift/details/:id" element={<TimeShiftDetailsPage/> } />

    </Routes>
  )
}
