import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';

import { Add, Edit } from '@mui/icons-material';
import timeShiftServices from '../timeShiftServices';


export default function TimeShiftForm({refreshList, AppAlert, masters, data=null}) {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    

    const initialValues = {
        title:"",
        code:"",
        shiftStartTime:"09:00",
        shiftEndTime:"18:00",
        //punchBeforeTime:"",
        //punchAfterTime:"",
        lateComeAfter:"10",
        earlyGoBefore:"10",
        break1StartTime:"11:00",
        break1EndTime:"11:15",
        break2StartTime:"14:30",
        break2EndTime:"15:00",
        minWorkingFullDay:"9",
        minWorkingHalfDay:"5",
        weekOffDay1:"",
        weekOffDay2:"",
        allowLateComeEarlyGo:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        timeShiftServices.save(values).then(res =>{
            refreshList();
            AppAlert(res.msg)
            setDialogStatus(false);
        }).catch(error => {
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        title:Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            title:data.title,
            code:data.code,
            shiftStartTime:data.shiftStartTime,
            shiftEndTime:data.shiftEndTime,
            //punchBeforeTime:data.punchBeforeTime,
            //punchAfterTime:data.punchAfterTime,
            lateComeAfter:data.lateComeAfter,
            earlyGoBefore:data.earlyGoBefore,
            break1StartTime:data.break1StartTime,
            break1EndTime:data.break1EndTime,
            break2StartTime:data.break2StartTime,
            break2EndTime:data.break2EndTime,
            minWorkingFullDay:data.minWorkingFullDay,
            minWorkingHalfDay:data.minWorkingHalfDay,
            weekOffDay1:data.weekOffDay1,
            weekOffDay2:data.weekOffDay2,
            allowLateComeEarlyGo:data.allowLateComeEarlyGo,

        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    return (
        <>
           {!data && <button className="btn btn-primary" onClick={addNew}><Add/> Add New</button>}
            {data &&
              <Tooltip title="Update">
                  <IconButton onClick={updateData} style={{padding:"0px"}}>
                      <Edit/>
                  </IconButton>
              </Tooltip>
            } 

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth=""
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"TimeShift"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-9">

                                                <div className="row pt-2">
                                                    

                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="text" label="Shift Name" name="title" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="text" label="Shift Code" name="code" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3 pt-2" ><FormikControl control="smallTextInput" formik={formik} type="time" label="Shift Start Time" name="shiftStartTime" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3 pt-2" ><FormikControl control="smallTextInput" formik={formik} type="time" label="Shift End Time" name="shiftEndTime" /></div>
                                                    {/*<div className="col-md-4 col-xs-12 pb-2" ><FormikControl control="smallTextInput" formik={formik} type="number" label="Punch Before Time" name="punchBeforeTime" /></div> */}
                                                    {/*<div className="col-md-4 col-xs-12 pb-2" ><FormikControl control="smallTextInput" formik={formik} type="number" label="Punch After Time" name="punchAfterTime" /></div> */}
                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="time" label="Late Come After" name="lateComeAfter" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="time" label="Early Go Before" name="earlyGoBefore" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3 pt-2" ><FormikControl control="smallTextInput" formik={formik} type="time" label="Break 1 Start Time" name="break1StartTime" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3 pt-2" ><FormikControl control="smallTextInput" formik={formik} type="time" label="Break 1 End Time" name="break1EndTime" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3 pt-2" ><FormikControl control="smallTextInput" formik={formik} type="time" label="Break 2 Start Time" name="break2StartTime" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3 pt-2" ><FormikControl control="smallTextInput" formik={formik} type="time" label="Break 2 End Time" name="break2EndTime" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="number" label="Min Working Full Day" name="minWorkingFullDay" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="number" label="Min Working Half Day" name="minWorkingHalfDay" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="number" label="Week Off Day 1" name="weekOffDay1" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="number" label="Week Off Day 2" name="weekOffDay2" /></div>
                                                    <div className="col-md-6 col-xs-12 pb-3" ><FormikControl control="smallTextInput" formik={formik} type="number" label="Allow Late Come Early Go" name="allowLateComeEarlyGo" /></div>
                                                    

                                                    
                                                </div>

                                                
                                            </div>

                                            
                                        </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}
