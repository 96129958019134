import { AccountCircle, CalendarMonthOutlined, DocumentScanner, Fingerprint, Groups, Home,  Label,  ManageAccounts, ManageHistory, MoreVert, Notifications, People, PersonAdd, Settings, TransferWithinAStation, WorkOutlined} from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom'


export default function Header() {
    
    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;

    const navigate = useNavigate(); 
    

    useEffect(()=>{
        if(authUser.verified === 0){
          navigate("/auth/profile");
        }
    },[authUser])


    return (
        <>  { userId && 
            <nav className="navbar shadow navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="/global-logo.png" height="50" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"14px"}}>
                                { authUser.role <= 3 && <>

                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="/employe-master/list" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Employees</a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to="/employee/list"> <Groups/>Employee List</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/employee-master/list">  <ManageAccounts /> Employee Masters</NavLink></li>
                                        </ul>
                                    </li>


                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="/employe-master/list" id="shiftDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Time Shift</a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="shiftDropdown">
                                            <li><NavLink className="dropdown-item" to="/timeshift/list"> <ManageHistory/> Shift Master</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/employee-master/list"> <PersonAdd/>   Assign Shift</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/employee-master/list"><DocumentScanner/>  Daily Log Import</NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink className="nav-link" aria-current="page" to="/"> <Fingerprint/>Attendance</NavLink>
                                    </li>

                                    

                                </>}
                        </ul>
                        
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <Notifications /> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li>
                                        <NavLink className="dropdown-item" to="/notification/list">
                                            <div className="row">
                                                <div className="col-md-2"> <AccountCircle/> </div>
                                                <div className="col-md-10"> Visitor 1 </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    For Property Tax
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li>
                                        <NavLink className="dropdown-item" to="/notification/list">
                                            <div className="row">
                                                <div className="col-md-2"> <AccountCircle/> </div>
                                                <div className="col-md-10"> Visitor 2 </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    For 
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                    
                                </ul>
                            </li>

                            { authUser.role == 1 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/system-master/list"><Label /> Status Master</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/business/details"><WorkOutlined/> Business Details</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    <li><a className="dropdown-item" href="/auth/profile">My Profile</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        }
        </>
    )
}
